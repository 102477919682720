<template>
  <div class="container">
      <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="模糊搜索名称"></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-input v-model="search.element_code" @change="Search" placeholder="元素编号"></el-input>
        </el-form-item> -->
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="create">新建</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="翻译内容">
        <template v-slot="scope">
          <span>{{ scope.row.element_content }}</span>
        </template>
      </el-table-column>
      <el-table-column label="语言简写">
        <template v-slot="scope">
          <span>{{ scope.row.language_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <el-button type="danger" size="small" @click="deletepageele(scope.row.id)">删除</el-button>
          <el-button type="primary" size="small" @click="editpageEle(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'页面元素翻译'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="翻译内容" prop="element_content" label-width="100px">
          <el-input v-model="form.element_content" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="语言简写" prop="language_code" label-width="100px">
          <el-select v-model="form.language_code">
            <el-option v-for="item in langlist" v-bind:key="item.code" v-bind:label="item.name" v-bind:value="item.code"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      title: '',
      pagecode: '',
      search: {
        page: 1,
        limit: 10,
        kname: '',
        element_code: '',
      },
      list: [],
      total: 0,
      edit: false,
      dialogFormVisible: false,
      form: {
        id: '',
        element_code: '',
        element_content: '',
        language_code: '',
      },
      rules: {
        element_content: [{ required: true, message: '请输入翻译内容', trigger: 'blur' }],
        language_code: [{ required: true, message: '请输入语言简写', trigger: 'blur' }],
      },
      langlist: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetLanguege () {
      this.api.LanguageList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.langlist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search () {
      this.api.ElementTransList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    create () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {
        id: '',
        element_code: '',
        element_content: '',
        language_code: '',
      }
      this.title = '新增'
      this.edit = false
      this.dialogFormVisible = true
    },
    goback (){
      this.$router.push('/iotmanage/multilanguage/pageelement/'+ this.pagecode)
    },
    editpageEle (item) {
      this.title = '修改'
      this.edit = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.api.ElementTransDetail(item).then(res => {
         if (res.data.code == 200) {
          this.dialogFormVisible = true
          this.form = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    deletepageele(item) {
      this.$confirm('是否删除该翻译?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.ElementTransDelete({
          id: item
        }).then(res => {
          if (res.data.code == 200) {
            this.Search()
            this.$message.success('删除成功')
            return
          }
          this.$message.error('删除失败'+ res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    addconfirm(){
      this.update('add')
    },
    update (item) {
      this.api.ElementTransUpdate({
        id: item==='add'?0:this.form.id,
        element_code: this.search.element_code,
        element_content: this.form.element_content,
        language_code: this.form.language_code
      }).then(res => {
        if (res.data.code == 200) {
          this.Search()
          this.$message.success(this.title + '成功')
          this.dialogFormVisible = false
          return
        }
        this.$message.error(this.title + '失败' + res.data.msg)
      })
    },
    editconfirm(){
      this.update()
    },
  },
  filters: {},
  mounted () {
    this.search.element_code = this.$route.params.code.split('&')[0]
    this.pagecode = this.$route.params.code.split('&')[1]
    this.Search()
  },
  created () {
    this.GetLanguege()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
